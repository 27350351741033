.about-container {
    padding: 40px;
    font-family: 'Arial', sans-serif;
    color: #333;
    background-color: #f9f9f9;
  }
  
  .about-header {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .about-header h1 {
    font-size: 3rem;
    color: #2c3e50;
  }
  
  .about-header p {
    font-size: 1.5rem;
    color: #7f8c8d;
    margin-top: 10px;
  }
  
  .about-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 900px;
    margin: 0 auto;
  }
  
  .about-description {
    margin-bottom: 50px;
    text-align: center;
  }
  
  .about-description h2 {
    font-size: 2rem;
    color: #2c3e50;
    margin-bottom: 20px;
  }
  
  .about-description p {
    font-size: 1.2rem;
    line-height: 1.6;
    color: #7f8c8d;
  }
  
  .about-team {
    text-align: center;
  }
  
  .about-team h2 {
    font-size: 2rem;
    margin-bottom: 30px;
    color: #2c3e50;
  }
  
  .team-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 40px;
  }
  
  .team-member {
    text-align: center;
  }
  
  .team-member img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 15px;
  }
  
  .team-member h3 {
    font-size: 1.5rem;
    color: #2c3e50;
  }
  
  .team-member p {
    color: #7f8c8d;
    font-size: 1.1rem;
  }
  