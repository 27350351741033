/* Main container for the page */
.main-page {
  display: flex;
  height: 100vh;  /* Full viewport height */
  background-color: #ffffff;  /* All-white background */
  justify-content: center;
  align-items: flex-start;  /* Align items closer to the top */
  padding: 20px;
  box-sizing: border-box;
}

/* Left section (Floating ChatBot card) */
.main-page-left {
  flex: 0 0 30%;  /* 30% of the width */
  display: flex;
  justify-content: center;
  align-items: flex-start;  /* Ensure the ChatBot stays at the top */
}

.floating-card {
  background-color: #f9f9f9;
  border-radius: 15px;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  max-width: 400px;  /* Limit the max width for the floating card */
  min-height: 300px;
  overflow-y: auto;  /* Allow scrolling if the chatbot overflows */
  text-align: center;
  margin-top: 20px;  /* Add a bit of spacing from the top */
}

/* Right section (News Cards) */
.main-page-right {
  flex: 0 0 65%;  /* 65% of the width */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.news-cards-container {
  display: flex;
  flex-direction: column;
  gap: 20px;  /* Space between news cards */
  width: 100%;
}

.news-card {
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
}

.news-card h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #333;
}

.news-card p {
  font-size: 1rem;
  margin-bottom: 15px;
  color: #666;
}

.news-card small {
  font-size: 0.9rem;
  color: #999;
}

/* Style the toggle news button */
.toggle-news-btn {
  background-color: #3498db;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;
  transition: background-color 0.3s ease;
}

.toggle-news-btn:hover {
  background-color: #2980b9;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .main-page {
    flex-direction: column;
    align-items: stretch;
    padding-top: 80px;  /* Add padding to prevent overlap with the header */
  }

  .main-page-left,
  .main-page-right {
    flex: 1;
    width: 100%;
    margin-bottom: 20px;
  }

  .floating-card,
  .news-card {
    max-width: none;
  }
}
