/* Container for the entire form */
.news-form-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 40px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
    color: #333;
  }
  
  /* Title for the form */
  .news-form-title {
    text-align: center;
    font-size: 2rem;
    color: #2c3e50;
    margin-bottom: 20px;
  }
  
  /* Form group layout */
  .form-group {
    margin-bottom: 20px;
  }
  
  /* Label styling */
  .form-group label {
    display: block;
    font-size: 1.2rem;
    color: #2c3e50;
    margin-bottom: 10px;
  }
  
  /* Input field styling */
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    transition: border-color 0.3s ease;
  }
  
  .form-group input:focus,
  .form-group textarea:focus {
    border-color: #3498db;
    outline: none;
  }
  
  /* Textarea specific styling */
  .form-group textarea {
    resize: vertical;
    min-height: 150px;
  }
  
  /* Button styling */
  .news-form-btn {
    width: 100%;
    padding: 10px;
    background-color: #3498db;
    color: white;
    border: none;
    font-size: 1.1rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .news-form-btn:hover {
    background-color: #2980b9;
  }
  
  /* Success and error messages */
  .success-message {
    color: #2ecc71;
    margin-top: 20px;
    text-align: center;
  }
  
  .error-message {
    color: #e74c3c;
    margin-top: 20px;
    text-align: center;
  }
  