/* Container for the whole manage news page */
.manage-news-container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 40px;
    background-color: #f4f7fc;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Title of the page */
  .manage-news-title {
    text-align: center;
    font-size: 2.5rem;
    color: #2c3e50;
    margin-bottom: 30px;
  }
  
  /* Table styling */
  .news-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .news-table th, 
  .news-table td {
    padding: 15px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .news-table th {
    background-color: #3498db;
    color: white;
  }
  
  .news-table tr:hover {
    background-color: #f1f1f1;
  }
  
  .no-news {
    text-align: center;
    padding: 20px;
    color: #7f8c8d;
  }
  
  /* Button styling */
  .edit-btn, 
  .delete-btn {
    padding: 8px 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 10px;
  }
  
  .edit-btn {
    background-color: #3498db;
    color: white;
  }
  
  .edit-btn:hover {
    background-color: #2980b9;
  }
  
  .delete-btn {
    background-color: #e74c3c;
    color: white;
  }
  
  .delete-btn:hover {
    background-color: #c0392b;
  }
  
  /* Error message styling */
  .error-message {
    color: #e74c3c;
    text-align: center;
    margin-bottom: 20px;
  }
  