/* Header Styles */
header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background: #333;
  color: white;
}

.logo {
  background: white;
  padding: 5px;
  border-radius: 5px;
  height: 150px; /* Increase the logo size */
}

nav a {
  color: white;
  margin: 0 10px;
  text-decoration: none;
}

button {
  background: #555;
  border: none;
  color: white;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 5px;
}

button:hover {
  background: #444;
}
