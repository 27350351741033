/* Container for the news list */
.news-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}

/* Individual news item */
.news-item {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.news-item:hover {
  transform: translateY(-5px);
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.2);
}

.news-item h3 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 10px;
}

.news-content {
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
  margin-bottom: 10px;
}

.news-content p {
  margin-bottom: 10px;
}

.news-content a {
  color: #3498db;
  text-decoration: none;
}

.news-content a:hover {
  text-decoration: underline;
}

.load-more-btn {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

.load-more-btn:hover {
  background-color: #0056b3;
}
