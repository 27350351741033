/* Global Styles */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background: #f5f5f5;
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background: #333;
  color: white;
}

header img {
  height: 150px;
}

nav a {
  color: white;
  margin: 0 10px;
  text-decoration: none;
}

button {
  background: #555;
  border: none;
  color: white;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 5px;
}

button:hover {
  background: #444;
}

/* Main Page Styles */
.main-page {
  padding: 20px;
}

.toggle-news-btn {
  margin-bottom: 20px;
}

.news-item {
  background: white;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.news-item h3 {
  margin-top: 0;
}

.news-item p {
  color: #555;
}

.read-more-btn {
  background: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 5px;
}

.read-more-btn:hover {
  background: #0056b3;
}
