/* Container for the whole admin dashboard */
.admin-dashboard-container {
    padding: 40px;
    font-family: 'Arial', sans-serif;
    background-color: #f4f7fc;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  /* Title of the dashboard */
  .dashboard-title {
    font-size: 3rem;
    color: #2c3e50;
    margin-bottom: 10px;
  }
  
  /* Subtitle with a description */
  .dashboard-subtitle {
    font-size: 1.5rem;
    color: #7f8c8d;
    margin-bottom: 40px;
  }
  
  /* Flex container for dashboard cards */
  .dashboard-actions {
    display: flex;
    justify-content: center;
    gap: 40px;
    flex-wrap: wrap;
    max-width: 1200px;
    width: 100%;
  }
  
  /* Card styling for each dashboard action */
  .dashboard-card {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 300px;
    transition: transform 0.3s ease;
  }
  
  .dashboard-card:hover {
    transform: translateY(-5px);
  }
  
  /* Title for each card */
  .dashboard-card h2 {
    font-size: 1.8rem;
    color: #2c3e50;
    margin-bottom: 15px;
  }
  
  /* Description for each action */
  .dashboard-card p {
    font-size: 1.1rem;
    color: #7f8c8d;
    margin-bottom: 20px;
  }
  
  /* Button styling */
/* Remove underline from link text inside buttons */
.dashboard-btn {
    text-decoration: none;  /* Remove underline */
    background-color: #3498db;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  /* Hover effect for the button */
  .dashboard-btn:hover {
    background-color: #2980b9;
  }
  