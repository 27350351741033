.chat-bot {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 16px;
    max-width: 400px;
    margin: 20px auto;
    background-color: #f9f9f9;
  }
  
  .chat-bot-image {
    text-align: center;
    margin-bottom: 16px;
  }
  
  .chat-bot-image img {
    max-width: 100px;
    border-radius: 50%;
  }
  
  .chat-bot-conversation {
    max-height: 300px;
    overflow-y: auto;
    margin-bottom: 16px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #fff;
  }
  
  .chat-bot-message {
    padding: 8px;
    margin-bottom: 8px;
    border-radius: 8px;
  }
  
  .user-message {
    background-color: #e0f7fa;
    align-self: flex-end;
  }
  
  .bot-message {
    background-color: #e0e0e0;
    align-self: flex-start;
  }
  
  .chat-bot-input {
    display: flex;
  }
  
  .chat-bot-input input {
    flex: 1;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 8px 0 0 8px;
    outline: none;
  }
  
  .chat-bot-input button {
    padding: 8px 16px;
    border: none;
    background-color: #007bff;
    color: #fff;
    border-radius: 0 8px 8px 0;
    cursor: pointer;
  }
  
  .chat-bot-input button:hover {
    background-color: #0056b3;
  }

  